import React from "react";
import _ from "lodash";
import scroll from "scroll";
const page = require("scroll-doc")();
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import AppContainer from "../AppContainer";
import Card from "../Card";
import List from "../List";
import { TextInput, Select } from "../Inputs";
import PaymentMethodInput from "../PaymentMethodInput";
import Loader from "../Loader";
import ContactDetails from "../ContactDetails";
import ErrorBlock from "../ErrorBlock";
import Button from "../Button";
import PaymentAPIScriptLoader, {
  shouldLoadScript,
} from "../PaymentAPIScriptLoader";
import { PAYMENT_ID } from "../../api/constants";
import { spreedlyTokenize, recache } from "../../api/spreedlyAPI";
import { PAYMENT } from "../../utils/constants";
import { setParams, getParams, navigateTo, removeParams } from "../../utils/location";
import styles from "./index.module.scss";
import MyGiftCardsView from "../MyGiftCardsView";
import { LOGIN_TYPES } from "../LoginView";
import { getAppMediaSrc, mediaTypes } from "../../utils/media";
import SelectInput from "../Inputs/Select";


const PERSONAL_MESSAGE = "PERSONAL_MESSAGE";

const RECIPIENT = {
  EMAIL: "R_EMAIL",
  PHONE_NUMBER: "R_PHONE",
  NAME: "R_NAME",
};

const SENDER_DETAILS_FIELDS = [
  PAYMENT.EMAIL,
  PAYMENT.PHONE_NUMBER,
  PAYMENT.NAME,
];

const RECIPIENT_DETAILS_FIELDS = [
  RECIPIENT.EMAIL,
  RECIPIENT.PHONE_NUMBER,
  RECIPIENT.NAME,
];
const FIELDS = [
  ...SENDER_DETAILS_FIELDS,
  ...RECIPIENT_DETAILS_FIELDS,
  PERSONAL_MESSAGE,
  PAYMENT.PAYMENT_METHOD_INPUT,
];

const RECIPIENT_DETAILS = "RECIPIENT_DETAILS";
const PAYMENT_DETAILS = "PAYMENT_DETAILS";
const GIFT_PERSONALIZATION = "GIFT_PERSONALIZATION";

const CONTACT_DETAILS = "CONTACT_DETAILS";

const SAME_PHONE_NUMBER_AS_USER_ERROR =
  "You can not send yourself a gift, please change the recipient details";

const errorkey = (key) => `${key}_ERROR`;

export default class GiftCardView extends React.Component {
  state = {
    ...FIELDS.reduce(
      (o, key) => ({ ...o, [key]: null, [errorkey(key)]: null }),
      {},
    ),
    shouldLoadPayment: false,
    errorComponent: null,
    animateErrorElement: false,
    chargeMultiple: 1,
    giftCardPage: "sendGiftCard",
    validatingForm: false,
    reloadCardNumber: undefined
  };

  cardRefs = {};
  fieldsRefs = {};

  componentDidMount() {
    const { user = {}, location, paymentTypeDetails } = this.props;
    const params = getParams(location);

    if (_.get(params, "reloadCardNumber")){
      console.log("reloadCardNumber:",_.get(params, "reloadCardNumber"));
      this.setState({reloadCardNumber: _.get(params, "reloadCardNumber")});
    }

    scroll.top(page);

    this.setState({ shouldLoadPayment: shouldLoadScript(paymentTypeDetails) });

    if (user.loggedIn) {
      this.props.loadPaymentMethods(_.get(paymentTypeDetails, "paymentType"));
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(
        _.get(prevProps.user, "giftCardState.data.giftCardId"),
        _.get(this.props.user, "giftCardState.data.giftCardId"),
      )
    ) {
      if (_.get(this.props.user, "giftCardState.data.giftCardId")) {
        const {
          paymentTypeDetails,
          pageContext: {
            business: {
              openChargeCard: { giftCardDiscountPercent },
            },
          },
        } = this.props;

        const customerDetails = {
          email: this.state.EMAIL,
          phoneNumber: this.state.PHONE_NUMBER,
          fullName: this.state.NAME,
        };

        this.preTokenizeOrRecacheIfNeeded(customerDetails)
          .then((tokenResponse) => {
            this.props.rechargeCard({
              chargeMultiple: this.state.chargeMultiple,
              customerDetails,
              paymentTypeDetails,
              ...this.state[PAYMENT.PAYMENT_METHOD_INPUT],
              tokenResponse,
              discountPercentage: giftCardDiscountPercent,
            });
          })

          .catch((err) => {
            console.log(err);
            this.setState({ validatingForm: false });
          });
      }
      // else {
      //   if (this.state.validatingForm) {
      //     this.setState({ validatingForm: false });
      //   }
      // }
    }
    // else {
    //   if (this.state.validatingForm) {
    //     this.setState({ validatingForm: false });
    //   }
    // }
  }

  onInputError = (fieldName) => (error) =>
    this.setState({ [`${fieldName}_ERROR`]: error });

  onInputValid = (fieldName) => (value) => {
    this.setState({ [`${fieldName}_ERROR`]: null, [fieldName]: value });
  };

  registerInput = (fieldName) => (ref) => {
    this.fieldsRefs = { ...this.fieldsRefs, [fieldName]: ref };
  };

  isValidField = (field) => !this.state[errorkey(field)] && !!this.state[field];

  getInputPropsFor = (inputId, refKey = "refEl") => ({
    [refKey]: this.registerInput(inputId),
    onValid: this.onInputValid(inputId),
    onError: this.onInputError(inputId),
    rtl: this.props.appStyles.rtl,
    T: this.props.T,
  });

  scrollToErrorComponent = () => {
    scroll.top(
      page,
      _.get(this.cardRefs, `[${this.state.errorComponent}].offsetTop`) - 150,
      () => {
        this.setState({ animateErrorElement: true }, () => {
          clearTimeout(this.elementAnimation);
          this.elementAnimation = setTimeout(
            () => this.setState({ animateErrorElement: false }),
            300,
          );
        });
      },
    );
  };

  isOneToOneRatio = () => {
    const {
      pageContext: {
        business: {
          openChargeCard: { cardAmount, price },
        },
      },
    } = this.props;

    const basePrice = _.get(cardAmount, "amount");
    return basePrice === 1 && basePrice === _.get(price, "amount");
  };

  isCardConnect = () => {
    return (
      _.get(this.props, "paymentTypeDetails.paymentType") ===
      PAYMENT_ID.CARD_CONNECT
    );
  };

  preTokenizeOrRecacheIfNeeded = (customerDetails) => {
    if (
      _.includes(
        [PAYMENT_ID.SPREEDLY_TOAST, PAYMENT_ID.SPREEDLY_PURCHASE],
        this.props.paymentTypeDetails.paymentType,
      ) &&
      this.isCurrentPaymentMethodRequireCVV()
    ) {
      if (this.state[PAYMENT.PAYMENT_METHOD_INPUT].spreedlyRecache) {
        return recache();
      }
      return spreedlyTokenize({
        fullName: customerDetails.fullName,
        ..._.pick(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, [
          "month",
          "year",
          "zipCode",
        ]),
      });
    } else if (
      this.isCardConnect() &&
      document.getElementById("cardConnectToken")
    ) {
      const token = _.get(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, "token");
      this.setState({
        [PAYMENT.PAYMENT_METHOD_INPUT]: {
          creditCard: {
            ..._.pick(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, [
              "zipCode", "month", "year"
            ]),
          },
        },
      });

      return Promise.resolve({
        token,
        paymentMethod: {
          ..._.pick(this.state[PAYMENT.PAYMENT_METHOD_INPUT].creditCard, [
            "zipCode", "month", "year"]),
          last_four_digits: token.substr(-4),
        },
      });
    }
    return Promise.resolve();
  };

  onSubmit = () => {
    if (this.state.shouldLoadPayment && !this.state.paymentLoaded) {
      console.error(
        "not handled, payment script should be loaded but it failed to load",
      );
      return;
    }

    this.setState({ validatingForm: true });

    Promise.all(
      _.map(
        FIELDS,
        (field) =>
          new Promise((resolve) => 
          this.fieldsRefs[field].validate((err, value) => {
              this.setState(
                {
                  [field]: value,
                  [errorkey(field)]: err,
                },
                resolve,
              );
            }),
          ),
      ),
    ).then(() => {
      if (!_.every(RECIPIENT_DETAILS_FIELDS, this.isValidField)) {
        this.setState(
          {
            errorComponent: RECIPIENT_DETAILS,
            validatingForm: false,
          },
          () => {
            this.scrollToErrorComponent();
          },
        );
        console.log("Invalid Form - recipient details");
        return;
      }
      if (!this.isValidField(PAYMENT.PAYMENT_METHOD_INPUT)) {
        this.setState(
          { errorComponent: PAYMENT_DETAILS, validatingForm: false },
          () => {
            this.scrollToErrorComponent();
          },
        );
        console.log("Invalid Form - payment details");
        return;
      }
      if (!this.isValidField(PERSONAL_MESSAGE)) {
        this.setState(
          { errorComponent: GIFT_PERSONALIZATION, validatingForm: false },
          () => {
            this.scrollToErrorComponent();
          },
        );
        console.log("Invalid Form - personal message");
        return;
      }

      console.log("Valid Form");
      const giftCardParams = {
        fromUserName: this.state.NAME,
        fromUserEmail: this.state.EMAIL,
        fromPhoneNumber: this.state.PHONE_NUMBER,
        message: this.state.PERSONAL_MESSAGE,
        toUserName: this.state[RECIPIENT.NAME],
        toUserPhoneNumber: this.state[RECIPIENT.PHONE_NUMBER],
        toUserEmail: this.state[RECIPIENT.EMAIL],
        chargeMultiple: this.state.chargeMultiple,
        reloadCardNumber : this.state.reloadCardNumber
      };
      if (this.props.googleReCaptchaProps) {
        this.props.googleReCaptchaProps
          .executeRecaptcha("giftcard")
          .then((recaptchaToken) => {
            if (recaptchaToken) {
              this.props.createGiftCard({
                ...giftCardParams,
                recaptchaToken,
              });
            } else {
              this.setState({ validatingForm: false });
            }
          })
          .catch((e) => {
            this.setState({ validatingForm: false });
          });
      } else {
        this.props.createGiftCard(giftCardParams);
      }
    });
  };

  getGiftCardAmountOptions = () => {
    const {
      appStyles,
      pageContext: {
        business: {
          currencySymbol,
          openChargeCard: { cardAmount, price },
        },
      },
      T,
    } = this.props;

    return _.times(4, (multiple) => {
      const amountToPay = appStyles.rtl
        ? `${(price.amount * (multiple + 1)).toFixed(2)}${currencySymbol}`
        : `${currencySymbol}${(price.amount * (multiple + 1)).toFixed(2)}`;
      const amountReceieved = appStyles.rtl
        ? `${(cardAmount.amount * (multiple + 1)).toFixed(2)}${currencySymbol}`
        : `${currencySymbol}${(cardAmount.amount * (multiple + 1)).toFixed(2)}`;
      return {
        label:
          amountReceieved === amountToPay
            ? amountToPay
            : `${amountToPay} (${T("recipient will get")} ${amountReceieved})`,
        value: multiple + 1,
      };
    });
  };

  resetAndNavigateToHome = () => {
    this.props.resetGiftCard();
    navigateTo("/");
  };

  isCurrentPaymentMethodRequireCVV = () => {
    const { user = {} } = this.props;
    if (!user.loggedIn) {
      return true;
    }
    const currentPaymentMethod = _.get(user, "paymentMethods.data[0]");
    if (!currentPaymentMethod) {
      return true;
    }

    // in case of a null this is was not defined therefore we will require cvv for any case.
    return currentPaymentMethod.cvvMandatory !== false;
  };

  openSendGiftCard = () => {
    this.setState({ giftCardPage: "sendGiftCard" });
  };

  openCheckBalance = () => {
    const {
      user = {},
      pageContext: {
        businessAppConfiguration: { enableValutecGiftCards },
      },
      openAuthModal,
    } = this.props;
    if (!user.loggedIn) {
      openAuthModal(LOGIN_TYPES.GIFT_CARD);
    } else {
      if (enableValutecGiftCards) {
        this.props.getExternalGiftCards();
      }
      this.setState({ giftCardPage: "checkBalance" });
    }
  };

  render() {
    const {
      appStyles,
      pageContext: {
        business: { currencySymbol, areaCode, openChargeCard },
        businessAppConfiguration,
      },
      T,
      app: { keyboardOpen },
      user = {},
      location,

      paymentTypeDetails,
      addExternalGiftCardToAccount,
      getExternalGiftCards,
      removeExternalGiftCardFromAccount,
      resetAddExternalGiftCardToAccount,
    } = this.props;

    const cardBackgroundImageSrc = getAppMediaSrc(
      mediaTypes.cardBackgroundImage,
      appStyles,
    );
    const { idRequired, enableValutecGiftCards } = businessAppConfiguration;
    const requireLoginForGiftCard = _.get(
      businessAppConfiguration,
      "requireLoginForGiftCard",
    );
    const giftCardsEnabled = _.get(
      businessAppConfiguration,
      "giftCardsEnabled",
    );

    const showGiftCard = requireLoginForGiftCard
      ? user.loggedIn && giftCardsEnabled
      : giftCardsEnabled;

    if (!showGiftCard) {
      return null;
    }

    const cardAmount = _.get(openChargeCard, "cardAmount", 0);
    const discountPercentage = _.get(openChargeCard, "giftCardDiscountPercent");

    const { giftCardState, userDetails, rechargeCard } = user;

    if (rechargeCard.sending) {
      return (
        <AppContainer.Content appStyles={appStyles}>
          <AppContainer.CenteredColumn>
            <Loader appStyles={appStyles} classNames={styles.Loader} />
            <strong>{T("Sending gift card...")}</strong>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    if (rechargeCard.sent) {
      const amountGivenPreview = appStyles.rtl
        ? `${(
            cardAmount.amount * _.get(giftCardState, "data.chargeMultiple", 0)
          ).toFixed(2)}${currencySymbol}`
        : `${currencySymbol}${(
            cardAmount.amount * _.get(giftCardState, "data.chargeMultiple", 0)
          ).toFixed(2)}`;
      return (
        <AppContainer.Content
          appStyles={appStyles}
          classNames={styles.DesktopGutters}
        >
          <AppContainer.CenteredColumn>
            <h2
              style={{
                ...appStyles.PageHeader,
                ...appStyles.TitleOnBackground,
              }}
            >
              {T("Thanks")} {this.state.NAME}!
            </h2>
            <p style={{ marginTop: 0 }}>
              {`${T("A gift card was sent to ")}${_.get(
                giftCardState,
                "data.toUserName",
              )}. ${T("A confirmation email was sent to ")} ${
                this.state.EMAIL
              }`}
            </p>
            <Card appStyles={appStyles}>
              <Card.Title light appStyles={appStyles}>
                {T("Gift Card Details")}
              </Card.Title>
              <Card.Content>
                <List tight>
                  <List.Item>
                    <span style={{ textAlign: "start" }}>{`${T(
                      "Recipient Phone Number",
                    )}: `}</span>
                    <span style={{ textAlign: "end" }}>
                      {_.get(giftCardState, "data.toUserPhoneNumber")}
                    </span>
                  </List.Item>
                  <List.Item>
                    <span style={{ textAlign: "start" }}>{`${T(
                      "Recipient Email",
                    )}: `}</span>
                    <span style={{ textAlign: "end" }}>
                      {_.get(giftCardState, "data.toUserEmail")}
                    </span>
                  </List.Item>
                  <List.Item>
                    <span style={{ flexShrink: 0 }}>{`${T(
                      "Personal Message",
                    )}: `}</span>
                    <span style={{ textAlign: "end" }}>
                      {_.get(giftCardState, "data.message")}
                    </span>
                  </List.Item>
                  <List.Item classNames={styles.GiftCardListItem}>
                    <span style={{ marginBottom: 16 }}>{`${T(
                      "Gift Card Preview",
                    )}: `}</span>
                    <div
                      className={styles.GiftCard}
                      style={
                        appStyles.chargeCardImageBackground
                          ? {
                              background: `url(${cardBackgroundImageSrc})`,
                              textShadow: "0 0 8px #333",
                            }
                          : { background: appStyles.accentColor }
                      }
                    >
                      <div className={styles.GiftCardTopRows}>
                        <span
                          style={{
                            color: appStyles.cardBackgroundColor,
                            fontSize: "1.4rem",
                            marginBottom: 16,
                            fontFamily: appStyles.bodyFont,
                          }}
                        >
                          {amountGivenPreview}
                        </span>
                        <span
                          style={{ color: appStyles.cardBackgroundColor }}
                          className={styles.GiftCardText}
                        >
                          {T("{{business_name}}")}
                        </span>
                      </div>
                      <div className={styles.GiftCardBottomRow}>
                        <span
                          style={{ color: appStyles.cardBackgroundColor }}
                          className={styles.GiftCardText}
                        >
                          {_.get(giftCardState, "data.toUserName")}
                        </span>
                        <span
                          style={{ color: appStyles.cardBackgroundColor }}
                          className={styles.GiftCardText}
                        >
                          {T("Gift Card")}
                        </span>
                      </div>
                    </div>
                  </List.Item>
                </List>
              </Card.Content>
              <Button
                onClick={this.resetAndNavigateToHome}
                appStyles={appStyles}
                centered
                marginTop
                classNames={styles.BackToHomeDesktop}
              >
                <span>{T("Back to home screen")}</span>
              </Button>
            </Card>
            <AppContainer.Footer
              relativePosition={keyboardOpen}
              appStyles={appStyles}
              transparentGradient={!appStyles.withoutFooterButtonGradient}
              center
              classNames={styles.BackToHomeMobile}
            >
              <AppContainer.Footer.Button
                onClick={this.resetAndNavigateToHome}
                appStyles={appStyles}
                center
                spread
              >
                <span>{T("Back to home screen")}</span>
              </AppContainer.Footer.Button>
            </AppContainer.Footer>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    const { requireZipCode } = paymentTypeDetails || {};
    const requireCVV = this.isCurrentPaymentMethodRequireCVV();

    return (
      <AppContainer.Content
        appStyles={appStyles}
        classNames={styles.DesktopGutters}
      >
        {this.state.shouldLoadPayment && (
          <PaymentAPIScriptLoader
            paymentTypeDetails={paymentTypeDetails}
            onLoad={() =>
              this.setState({
                paymentLoaded: true,
              })
            }
          />
        )}
        {(rechargeCard.error ||
          this.state[`${PAYMENT.PHONE_NUMBER}_ERROR`] ===
            SAME_PHONE_NUMBER_AS_USER_ERROR) && (
          <ErrorBlock classNames={styles.ErrorBlock} appStyles={appStyles}>
            {T(
              `${rechargeCard.error ||
                this.state[`${PAYMENT.PHONE_NUMBER}_ERROR`]}`,
            )}
          </ErrorBlock>
        )}
        <h2
          className={styles.Title}
          style={{
            color: appStyles.accentColor,
            ...appStyles.GiftCardTitle,
          }}
        >
          {T("Send a gift card to someone you love!")}
        </h2>

        {enableValutecGiftCards && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: 1,
              padding: 12,
            }}
          >
            <Button
              onClick={this.openSendGiftCard}
              appStyles={appStyles}
              centered
              classNames={styles.PaymentTypeButton}
              style={
                this.state.giftCardPage === "sendGiftCard"
                  ? {
                      backgroundColor: appStyles.actionColor,
                      border: `2px solid ${appStyles.actionColor}`,
                      color: appStyles.cardBackgroundColor,
                      maxWidth: "43%",
                      margin: "0 6px",
                    }
                  : {
                      backgroundImage: "none",
                      backgroundColor: "transparent",
                      border: `2px solid ${appStyles.actionColor}`,
                      color: appStyles.actionColor,
                      maxWidth: "43%",
                    }
              }
            >
              <span>{T("Send a Gift Card")}</span>
            </Button>

            <Button
              onClick={this.openCheckBalance}
              appStyles={appStyles}
              centered
              classNames={styles.PaymentTypeButton}
              style={
                this.state.giftCardPage === "checkBalance"
                  ? {
                      backgroundColor: appStyles.actionColor,
                      border: `2px solid ${appStyles.actionColor}`,
                      color: appStyles.cardBackgroundColor,
                      maxWidth: "43%",
                    }
                  : {
                      backgroundImage: "none",
                      backgroundColor: "transparent",
                      border: `2px solid ${appStyles.actionColor}`,
                      color: appStyles.actionColor,
                      maxWidth: "43%",
                    }
              }
            >
              <span>{T("Check Balance")}</span>
            </Button>
          </div>
        )}

        {this.state.giftCardPage === "checkBalance" && (
          <span>
            {enableValutecGiftCards && user.loggedIn && (
              <div>
                <MyGiftCardsView
                  appStyles={appStyles}
                  T={T}
                  currencySymbol={currencySymbol}
                  addExternalGiftCardToAccount={addExternalGiftCardToAccount}
                  removeExternalGiftCardFromAccount={
                    removeExternalGiftCardFromAccount
                  }
                  getExternalGiftCards={getExternalGiftCards}
                  resetAddExternalGiftCardToAccount={
                    resetAddExternalGiftCardToAccount
                  }
                  user={user}
                  openInputFields={true}
                />
                <List.Separator />
              </div>
            )}
          </span>
        )}

        {this.state.giftCardPage === "sendGiftCard" && (
          <>
            <Card
              appStyles={appStyles}
              hasErrors={this.state.errorComponent === GIFT_PERSONALIZATION}
              animateError={
                this.state.errorComponent === GIFT_PERSONALIZATION &&
                this.state.animateErrorElement
              }
              refEl={(ref) => (this.cardRefs[GIFT_PERSONALIZATION] = ref)}
            >
              <Card.Title appStyles={appStyles}>
                {T("Personalize Your Gift")}
              </Card.Title>
              {discountPercentage > 0 && (
                <Card.Content
                  style={{
                    margin: "0 16px",
                    fontStyle: "italic",
                  }}
                >
                  {T(
                    `Get a ${discountPercentage}% discount on any amount you choose to gift`,
                  )}
                </Card.Content>
              )}
              <Card.Content
                style={{
                  margin: "0 16px",
                }}
              >
                <div
                  style={{
                    marginBottom: 6,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {T("Gift Amount")}
                  {Boolean(
                    _.isNumber(discountPercentage) &&
                      discountPercentage > 0 &&
                      this.state.chargeMultiple > 1,
                  ) && (
                    <strong>
                      {T("Price: ")}
                      <small
                        style={{
                          textDecoration: "line-through",
                          fontStyle: "italic",
                        }}
                      >
                        {currencySymbol}
                        {Number(this.state.chargeMultiple).toFixed(2)}
                      </small>{" "}
                      <span style={{ color: appStyles.accentColor }}>
                        {currencySymbol}
                        {(
                          (this.state.chargeMultiple *
                            (100 - discountPercentage)) /
                          100
                        ).toFixed(2)}
                      </span>
                    </strong>
                  )}
                </div>
                {this.isOneToOneRatio() ? (
                  <TextInput
                    pattern="/^[0-9]+$/"
                    type="number"
                    min="1"
                    step="any"
                    appStyles={appStyles}
                    validator={(value) => value % 1 === 0 && value > 0}
                    errorMessage={T(
                      "Sorry, we accept only whole numbers as charge amount",
                    )}
                    onChange={(value) =>
                      this.setState({ chargeMultiple: value })
                    }
                  />
                ) : (
                  <SelectInput
                    appStyles={appStyles}
                    onChange={(chargeMultiple) =>
                      this.setState({ chargeMultiple })
                    }
                    options={this.getGiftCardAmountOptions()}
                    noCheckmark
                    value={this.state.chargeMultiple}
                    placeholder={T("Gift Amount")}
                  />
                )}
                <div style={{ marginBottom: 6, marginTop: 16 }}>
                  {T("Personal Message")}
                </div>
                <TextInput
                  {...this.getInputPropsFor(PERSONAL_MESSAGE)}
                  appStyles={appStyles}
                  placeholder={T("Write a personal message!")}
                  noCheckmark
                  validator={(input) => input !== ""}
                  initialValue={this.state.reloadCardNumber ? `Adding balance to card #${this.state.reloadCardNumber}` : undefined}
                  errorMessage={T("Please enter a personal message")}
                />
              </Card.Content>
            </Card>
            <ContactDetails
              T={T}
              appStyles={appStyles}
              hasErrors={this.state.errorComponent === RECIPIENT_DETAILS}
              animateError={
                this.state.errorComponent === RECIPIENT_DETAILS &&
                this.state.animateErrorElement
              }
              refEl={(ref) => (this.cardRefs[RECIPIENT_DETAILS] = ref)}
              inputProps={{
                [PAYMENT.NAME]: {
                  ...this.getInputPropsFor(RECIPIENT.NAME),
                  autoComplete: "chrome-off",
                  initialValue: (user.loggedIn && this.state.reloadCardNumber) ?
                    userDetails.data.name : _.get(giftCardState, "contactDetails.fullName"),
                },
                [PAYMENT.PHONE_NUMBER]: {
                  ...this.getInputPropsFor(RECIPIENT.PHONE_NUMBER),
                  autoComplete: "chrome-off",
                  initialValue:  (user.loggedIn && this.state.reloadCardNumber) ?
                  userDetails.data.phoneNumber : _.get(
                    giftCardState,
                    "contactDetails.phoneNumber",
                  ),
                },
                [PAYMENT.EMAIL]: {
                  ...this.getInputPropsFor(RECIPIENT.EMAIL),
                  autoComplete: "chrome-off",
                  initialValue:  (user.loggedIn && this.state.reloadCardNumber) ?
                    userDetails.data.email :  _.get(giftCardState, "contactDetails.email")
                },
              }}
              title={T("Recipient Details")}
              areaCode={areaCode}
            />
            <ContactDetails
              T={T}
              appStyles={appStyles}
              hasErrors={this.state.errorComponent === CONTACT_DETAILS}
              animateError={
                this.state.errorComponent === CONTACT_DETAILS &&
                this.state.animateErrorElement
              }
              refEl={(ref) => (this.cardRefs[CONTACT_DETAILS] = ref)}
              inputProps={{
                [PAYMENT.NAME]: {
                  ...this.getInputPropsFor(PAYMENT.NAME),
                  initialValue: user.loggedIn
                    ? userDetails.data.name
                    : this.state[PAYMENT.NAME],
                },
                [PAYMENT.PHONE_NUMBER]: {
                  ...this.getInputPropsFor(PAYMENT.PHONE_NUMBER),
                  value: user.loggedIn && userDetails.data.phoneNumber,
                  disabled: user.loggedIn,
                },
                [PAYMENT.EMAIL]: {
                  ...this.getInputPropsFor(PAYMENT.EMAIL),
                  initialValue: user.loggedIn
                    ? userDetails.data.email
                    : this.state[PAYMENT.EMAIL],
                },
              }}
              title={T("Your Details")}
              getInputPropsFor={this.getInputPropsFor}
              areaCode={areaCode}
            />
            <Card appStyles={appStyles} style={{ paddingBottom: "0px" }}>
              <PaymentMethodInput
                user={this.props.user}
                appStyles={appStyles}
                T={T}
                getInputPropsFor={this.getInputPropsFor}
                changeTargetURL={setParams("/payment-methods", {
                  ...getParams(location),
                  backPath: "/gift-card",
                })}
                idRequired={idRequired}
                requireZipCode={requireZipCode}
                requireCVV={requireCVV}
                ref={this.registerInput(PAYMENT.PAYMENT_METHOD_INPUT)}
                hasErrors={this.state.errorComponent === PAYMENT_DETAILS}
                animateError={
                  this.state.errorComponent === PAYMENT_DETAILS &&
                  this.state.animateErrorElement
                }
                refEl={(ref) => (this.cardRefs[PAYMENT_DETAILS] = ref)}
                paymentTypeDetails={paymentTypeDetails}
                paymentScriptLoaded={this.state.paymentLoaded}
              />
            </Card>
            <AppContainer.Footer
              relativePosition={keyboardOpen}
              appStyles={appStyles}
              transparentGradient={!appStyles.withoutFooterButtonGradient}
              center
            >
              <AppContainer.Footer.Button
                onClick={this.onSubmit}
                loading={
                  !this.props.user.rechargeCard.error &&
                  (giftCardState.sending ||
                    giftCardState.sent ||
                    this.state.validatingForm)
                }
                appStyles={appStyles}
                center
                classNames={styles.DesktopGutters}
              >
                {T("Send gift card")}
              </AppContainer.Footer.Button>
            </AppContainer.Footer>
          </>
        )}
      </AppContainer.Content>
    );
  }
}

export const GiftCardViewWithRecaptcha = withGoogleReCaptcha(GiftCardView);
